import React from 'react';
import Link from 'next/link';
import { makeStyles, Theme } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Body, Icons } from '@jobdone/jobdone-web-design-system';
import GlobeEarth from '@common/assets/img/GlobeEarth';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import FranceFlag from '@common/assets/img/FranceFlag';
import ItalyFlag from '@common/assets/img/ItalyFlag';
import UKFlag from '../assets/img/UKFlag';
import GermanyFlag from '../assets/img/GermanyFlag';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    transition: 'all .2s ease',
    padding: 0,
    fontSize: 13,
    color: theme.palette.grey[700],
    cursor: 'pointer',
    '&:hover': {
      color: '#4589ff',
    },
    '&:active': {
      color: '#3467bf',
    },
  },
  changeLangText: {
    display: 'flex',
    transition: 'all .2s ease',
    color: theme.palette.grey[700],
    textDecoration: 'none',
    '&:hover': {
      color: '#4589ff',
      textDecoration: 'underline',
    },
    '&:active': {
      color: '#3467bf',
    },
  },
  selectRoot: {
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
    '&:active': {
      backgroundColor: 'unset',
    },
    backgroundColor: 'unset !important',
  },
  select: {
    padding: '0 8px 0 0 !important',
    '&:active': {
      backgroundColor: 'unset',
    },
  },
  endAdornment: {
    display: 'none',
  },
  globeIcon: {
    paddingRight: 8,
  },
  dropdownPaper: {
    borderRadius: 8,
  },
  dropdownIcon: {
    transform: 'translateY(-2px) translateX(-6px)',
  },
  menuItem: {
    display: 'flex',
    fontSize: 15,
    color: theme.palette.common.black,
  },
  linkText: {
    fontWeight: 500,
    '& > a': {
      textDecoration: 'none',
    },
  },
  activeLinkText: {
    fontWeight: 700,
  },
  flag: {
    display: 'flex',
    alignSelf: 'center',
    marginRight: 10,
  },
  checkIcon: {
    color: theme.palette.primary.main,
    transform: 'scale(1.5) translateY(2px)',
    position: 'absolute',
    right: 15,
    '& > svg': {
      fontSize: 16,
      width: 16,
    },
  },
}));

const LangChanger = () => {
  const styles = useStyles();
  const router = useRouter();
  const { t } = useTranslation('common');

  const languages = [
    { value: 'en-US', label: t('languages.english'), emoji: <UKFlag /> },
    { value: 'de-DE', label: t('languages.german'), emoji: <GermanyFlag /> },
    // { value: 'ru-RU', label: t('languages.russian'), emoji: <RussiaFlag /> },
    // { value: 'az-AZ', label: t('languages.azerbaijani'), emoji: <AzerbaijaniFlag /> },
    // { value: 'ar-AR', label: t('languages.armenian'), emoji: <ArmeniaFlag /> },
    { value: 'it-IT', label: t('languages.italian'), emoji: <ItalyFlag /> },
    { value: 'fr-FR', label: t('languages.french'), emoji: <FranceFlag /> },
    // { value: 'ua-UA', label: t('languages.ukranian'), emoji: <UkraineFlag /> },
  ];

  return (
    <Select
      value={-1}
      defaultValue={-1}
      disableUnderline
      IconComponent={() => <Icons.DropdownIcon className={styles.dropdownIcon} />}
      classes={{
        root: styles.selectRoot,
        select: styles.select,
        icon: styles.icon,
      }}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        classes: {
          paper: styles.dropdownPaper,
        },
      }}
    >
      <MenuItem style={{ display: 'none' }} value={-1}>
        <Body className={styles.changeLangText}>
          {' '}
          <GlobeEarth className={styles.globeIcon} />
          {' '}
          {t('change_language')}
        </Body>
      </MenuItem>
      {languages.map(({ label, value, emoji }) => (
        <Link href={router.asPath} locale={value}>
          <MenuItem
            className={
            router.locale === value ? cx(styles.activeLinkText, styles.linkText) : styles.linkText
          }
            key={value}
            value={value}
          >
            <span className={styles.menuItem}>
              <span className={styles.flag}>{emoji}</span>
              {label}
              {router.locale === value && <Icons.Check className={styles.checkIcon} />}
            </span>
          </MenuItem>
        </Link>
      ))}
    </Select>
  );
};

export default LangChanger;
