import React from 'react';
import useStyles from './styles';

const FranceFlag = (props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLSpanElement> & React.HTMLAttributes<HTMLSpanElement>) => (
  <span className={useStyles().jobDoneIcon} {...props}>
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.0965 0H6.90418C3.09689 0 0 3.09689 0 6.90347V13.0965C0 16.9031 3.09689 20 6.90418 20H13.0965C16.9031 20 20 16.9031 20 13.0965V6.90347C20.0007 3.09618 16.9031 0 13.0965 0Z' fill='#EFEFEF' />
      <path d='M6.32702 0.0290527C2.78924 0.324164 0.000976562 3.29163 0.000976562 6.90336V13.0964C0.000976562 16.7082 2.78995 19.6756 6.32702 19.9707V0.0290527Z' fill='#282780' />
      <path d='M13.6738 0.0290527V19.9707C17.2109 19.6756 19.9999 16.7082 19.9999 13.0964V6.90336C20.0006 3.29092 17.2109 0.323453 13.6738 0.0290527Z' fill='#CE2B37' />
    </svg>
  </span>
);

export default FranceFlag;
