import React from 'react';
import useStyles from './styles';

const ItalyFlag = (props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLSpanElement> & React.HTMLAttributes<HTMLSpanElement>) => (
  <span className={useStyles().jobDoneIcon} {...props}>
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14.2649 0.453369H7.7339C3.7184 0.453369 0.452148 3.71962 0.452148 7.73437V14.2661C0.452148 18.2809 3.7184 21.5471 7.7339 21.5471H14.2649C18.2796 21.5471 21.5459 18.2809 21.5459 14.2661V7.73437C21.5466 3.71887 18.2804 0.453369 14.2649 0.453369V0.453369Z' fill='#EFEFEF' />
      <path d='M7.12512 0.484131C3.39462 0.795381 0.453125 3.92513 0.453125 7.73438V14.2661C0.453125 18.0754 3.39462 21.2051 7.12512 21.5164V0.484131Z' fill='#009246' />
      <path d='M14.874 0.484131V21.5164C18.6045 21.2051 21.546 18.0754 21.546 14.2661V7.73438C21.5468 3.92438 18.6045 0.794631 14.874 0.484131V0.484131Z' fill='#CE2B37' />
    </svg>
  </span>
);

export default ItalyFlag;
